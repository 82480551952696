import React, { useEffect, useState } from "react"
import { Loading } from "@sixsense/core/components";

import { Alert, Card, Col, Row } from "antd"
import { css } from "@emotion/css";

export default function WebTag() {
    return (<div className={css`
    padding: 15px;
    `}>
        <Row gutter={6}>
            <Col>
                <Card title="WebTag Loader">
                    <WebTagLoader />
                </Card>
                </Col>
                <Col>
                <Card title="6signal">
                    <CompanyDetails />
                </Card>
            </Col>
        </Row>

    </div>)
}
declare global {
    interface Window {
        _6si: any;
    }
}

enum TagState {
    Loading,
    Loaded,
    Error
}

function WebTagLoader() {
    const [tagState, setTageState] = useState(TagState.Loading);
    useEffect(() => {
        if((window as any).loadedTag) {
            return
        } else {
            (window as any).loadedTag = true;
        }
        window._6si = window._6si || [];
        window._6si.push(["enableEventTracking", !0]);
        window._6si.push(["setToken", "theblahblahblah"]);
        window._6si.push(["enableRetargeting", false]);
        window._6si.push(["setEndpoint", "6sense-cf-webtag-proxy.com"]);
        (function () {
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.async = !0;
            a.src = "https://6sense-cf-webtag-proxy.com/6si.min.js";
            a.onload = () => {
                setTageState(TagState.Loaded);
            }
            a.onerror = () => {
                setTageState(TagState.Error);
            }
            var b = document.getElementsByTagName("script")[0]; b.parentNode?.insertBefore(a, b)
        }
        )();
    }, [false])
    return (<>
        {tagState == TagState.Loading && <>Loading WebTag on Page: <Loading type="spinner" /></>}
        {tagState == TagState.Loaded && <Alert message="WebTag JS Loaded from Worker" type="success" />}
        {tagState == TagState.Error && <Alert message="WebTag JS Failed to Load" type="error" />}
    </>);
}

function CompanyDetails() {
    const [tagState, setTagState] = useState(TagState.Loading);
    const [data, setData] = useState<any>({});
    useEffect(()=>{
        window._6si = window._6si || [];
        window._6si.push(["enableCompanyDetails", true, function(data: any) {
            setData(JSON.parse(data));
            setTagState(TagState.Loaded);
        }]);
    }, []);
    return (<>
        {tagState == TagState.Loading && <>Loading 6signal Data: <Loading type="spinner" /></>}
        {tagState == TagState.Loaded && <>
        <table>
            <tbody>
            <tr>
                <td><b>Company Name</b></td>
                <td>{data.company.name}</td>
            </tr>
            <tr>
                <td><b>Company Industry</b></td>
                <td>{data.company.industry}</td>
            </tr>
            </tbody>
        </table>
        </>}
        {tagState == TagState.Error && <Alert message="6signal data failed to load" type="error" />}
    </>);

}